@import "fonts.css";

html,
body,
#root {
  margin: 0;
  background-color: #ffffff;
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100%;
  padding: 0 !important;
  scroll-behavior: smooth;
}

* {
  -webkit-locale: auto;
}

.grecaptcha-badge { visibility: hidden; }